<template>
    <div>
        <CustomHeader title="崆峒山年卡" @change="getData"></CustomHeader>
        <div class="layout">
            <el-row :gutter="20">
                <el-col :xs="24" :md="6">
                    <dv-border-box-12>
                        <div class="item-box1">
                            <div class="item-title">年卡办理统计</div>
                            <div class="item-content">
                                <div class="item-sub">
                                    <div class="item-subtitle">今日办理</div>
                                    <dv-digital-flop :config="{number:[list['user_now']['now']],content:'{nt}张',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                                <div class="item-sub">
                                    <div class="item-subtitle">昨日办理</div>
                                    <dv-digital-flop :config="{number:[list['user_now']['tow']],content:'{nt}张',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                                <div class="item-sub">
                                    <div class="item-subtitle">今年办理</div>
                                    <dv-digital-flop :config="{number:[list['user_now']['year']],content:'{nt}张',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                                <div class="item-sub">
                                    <div class="item-subtitle">去年办理</div>
                                    <dv-digital-flop :config="{number:[list['user_now']['year']],content:'{nt}张',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                            </div>
                        </div>
                    </dv-border-box-12>
                </el-col>
                <el-col :xs="24" :md="12">
                    <dv-border-box-12>
                        <div class="item-box1">
                            <div class="item-title">年卡年龄统计</div>
                            <div class="item-content">
                                <eCharts :options="option2"></eCharts>
                            </div>
                        </div>
                    </dv-border-box-12>
                </el-col>
                <el-col :xs="24" :md="6">
                    <dv-border-box-12>
                        <div class="item-box1">
                            <div class="item-title">办卡用户性别统计</div>
                            <div class="item-content">
                                <eCharts :options="option1"></eCharts>
                            </div>
                        </div>
                    </dv-border-box-12>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :md="8">
                    <dv-border-box-12>
                        <div class="item-box2">
                            <div class="item-title">年卡用户民族占比</div>
                            <div class="item-content">
                                <eCharts :options="option3"></eCharts>
                            </div>
                        </div>
                    </dv-border-box-12>
                </el-col>
                <el-col :xs="24" :md="16">
                    <dv-border-box-12>
                        <div class="item-box1">
                            <div class="item-title">办理月份统计</div>
                            <div class="item-content">
                                <eCharts :options="option4"></eCharts>
                            </div>
                        </div>
                    </dv-border-box-12>
                    <dv-border-box-12>
                        <div class="item-box1">
                            <div class="item-title">办理地区统计</div>
                            <div class="item-content">
                                <eCharts :options="option5"></eCharts>
                            </div>
                        </div>
                    </dv-border-box-12>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import eCharts from "../components/echarts"
    import {getNiankaInfo} from "../api/common";
    import CustomHeader from "../components/CustomHeader";
    import {mapState} from "vuex";
    export default {
        name: "Nianka",
        components:{eCharts,CustomHeader},
        computed:{
            ...mapState(['linear_color','echarts_color1','echarts_color2','echarts_color3','echarts_color4','echarts_color5'])
        },
        data(){
          return{
              list:{
                  user_now:{
                      now:0,
                      tow:0,
                      year:0
                  }
              },
              option1:{},//性别
              option2:{},//年龄
              option3:{},//名族
              option4:{},//月份
              option5:{},//地区
          }
        },
        mounted() {
            this.getData()
            setInterval(()=>{
                this.getData()
            },60*1000)
        },
        methods:{
            async getData(e){
                let res = await getNiankaInfo(e);
                this.list = res.data
                let symbols = [
                    'path://M18.2629891,11.7131596 L6.8091608,11.7131596 C1.6685112,11.7131596 0,13.032145 0,18.6237673 L0,34.9928467 C0,38.1719847 4.28388932,38.1719847 4.28388932,34.9928467 L4.65591984,20.0216948 L5.74941883,20.0216948 L5.74941883,61.000787 C5.74941883,65.2508314 11.5891201,65.1268798 11.5891201,61.000787 L11.9611506,37.2137775 L13.1110872,37.2137775 L13.4831177,61.000787 C13.4831177,65.1268798 19.3114787,65.2508314 19.3114787,61.000787 L19.3114787,20.0216948 L20.4162301,20.0216948 L20.7882606,34.9928467 C20.7882606,38.1719847 25.0721499,38.1719847 25.0721499,34.9928467 L25.0721499,18.6237673 C25.0721499,13.032145 23.4038145,11.7131596 18.2629891,11.7131596 M12.5361629,1.11022302e-13 C15.4784742,1.11022302e-13 17.8684539,2.38997966 17.8684539,5.33237894 C17.8684539,8.27469031 15.4784742,10.66467 12.5361629,10.66467 C9.59376358,10.66467 7.20378392,8.27469031 7.20378392,5.33237894 C7.20378392,2.38997966 9.59376358,1.11022302e-13 12.5361629,1.11022302e-13',
                    'path://M28.9624207,31.5315864 L24.4142575,16.4793596 C23.5227152,13.8063773 20.8817445,11.7111088 17.0107398,11.7111088 L12.112691,11.7111088 C8.24168636,11.7111088 5.60080331,13.8064652 4.70917331,16.4793596 L0.149791395,31.5315864 C-0.786976655,34.7595013 2.9373074,35.9147532 3.9192135,32.890727 L8.72689855,19.1296485 L9.2799493,19.1296485 C9.2799493,19.1296485 2.95992025,43.7750224 2.70031069,44.6924335 C2.56498417,45.1567684 2.74553639,45.4852068 3.24205501,45.4852068 L8.704461,45.4852068 L8.704461,61.6700801 C8.704461,64.9659872 13.625035,64.9659872 13.625035,61.6700801 L13.625035,45.360657 L15.5097899,45.360657 L15.4984835,61.6700801 C15.4984835,64.9659872 20.4191451,64.9659872 20.4191451,61.6700801 L20.4191451,45.4852068 L25.8814635,45.4852068 C26.3667633,45.4852068 26.5586219,45.1567684 26.4345142,44.6924335 C26.1636859,43.7750224 19.8436568,19.1296485 19.8436568,19.1296485 L20.3966199,19.1296485 L25.2043926,32.890727 C26.1862111,35.9147532 29.9105828,34.7595013 28.9625083,31.5315864 L28.9624207,31.5315864 Z M14.5617154,0 C17.4960397,0 19.8773132,2.3898427 19.8773132,5.33453001 C19.8773132,8.27930527 17.4960397,10.66906 14.5617154,10.66906 C11.6274788,10.66906 9.24611767,8.27930527 9.24611767,5.33453001 C9.24611767,2.3898427 11.6274788,0 14.5617154,0 L14.5617154,0 Z',
                ];
                let bodyMax = res.data['user_sex']['count'][0]['value']+res.data['user_sex']['count'][1]['value']; //指定图形界限的值
                let labelSetting = {
                    show: true,
                    position: 'bottom',
                    offset: [0, -20],
                    formatter: (param)=> {
                        return (param.value / bodyMax * 100).toFixed(0) + '%';
                    },
                    textStyle: {
                        fontSize: 18,
                        fontFamily: 'Arial'
                    }
                };
                this.option1 = {
                    tooltip: {
                        show:true
                    },
                    xAxis: {
                        data: ['男', '女'],
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {show: false}
                    },
                    yAxis: {
                        max: bodyMax,
                        splitLine: {show: false},
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {show: false}
                    },
                    grid: {
                        top:'10%',
                        left: '30%',
                        right: '30%',
                        bottom:0,
                        containLabel:true
                    },
                    series: [
                        {
                        name: 'typeA',
                        type: 'pictorialBar',
                        symbolClip: true,
                        symbolBoundingData: bodyMax,
                        label: labelSetting,
                        data: [
                            {
                                value: res.data['user_sex']['count'][1]['value'],
                                symbol: symbols[0],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(105,204,230)' //单独控制颜色
                                    }
                                },
                            },
                            {
                                value: res.data['user_sex']['count'][0]['value'],
                                symbol: symbols[1],
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(255,130,130)' //单独控制颜色
                                    }
                                },
                            }
                        ],
                        //markLine: markLineSetting,
                        z: 10
                    },
                        {
                            // 设置背景底色，不同的情况用这个
                            name: '性别统计',
                            type: 'pictorialBar', //异型柱状图 图片、SVG PathData
                            symbolBoundingData: bodyMax,
                            animationDuration: 0,
                            itemStyle: {
                                normal: {
                                    color: '#ccc' //设置全部颜色，统一设置
                                }
                            },
                            z: 10,
                            data: [
                                {
                                    itemStyle: {
                                           normal: {
                                            color: 'rgba(105,204,230,0.40)' //单独控制颜色
                                        }
                                    },
                                    value: res.data['user_sex']['count'][1]['value'],
                                    symbol: symbols[0]
                                },
                                {
                                    itemStyle: {
                                        normal: {
                                            color: 'rgba(255,130,130,0.40)' //单独控制颜色
                                        }
                                    },
                                    value: res.data['user_sex']['count'][0]['value'],
                                    symbol: symbols[1]
                                }
                            ]
                        }
                    ]
                }
                this.option2 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none'
                        },
                    },
                    grid:{
                      top:'20%',
                      bottom:'0',
                      containLabel:true
                    },
                    xAxis: {
                        data: res.data['user_age'].map(item=>item.name),
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {
                            color: '#ffffff'
                        }
                    },
                    yAxis: {
                        splitLine: {show: false},
                        axisTick: {show: false},
                        axisLine: {show: false},
                        axisLabel: {show: false},
                    },
                    series: [{
                        name: '办卡年龄',
                        type: 'pictorialBar',
                        barCategoryGap: '-130%',
                        // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                        symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                        itemStyle: {
                            opacity: 0.5,
                            normal: {
                                color: (params)=> {
                                    let colorList = [
                                        'rgba(55, 112, 255,0.7)', 'rgba(79, 116, 255,0.7)',
                                        'rgba(83, 116, 255,0.7)', 'rgba(85, 125, 255,0.7)',
                                        'rgba(101, 191, 255,0.7)', 'rgba(96, 227, 255,0.7)',
                                        'rgba(25, 255, 224,0.7)','rgba(25, 225, 224,0.7)'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                opacity: 1
                            }
                        },
                        label:{
                          show:true,
                          position:'top'
                        },
                        data:res.data['user_age'].map(item=>item.value),
                        z: 10
                    },]
                };
                // this.option2 = {
                //     baseOption:{
                //         tooltip: {
                //             trigger: 'axis',
                //             axisPointer: { // 坐标轴指示器，坐标轴触发有效
                //                 axis:'y',
                //                 snap:true,
                //                 type: 'cross' ,
                //                 animation :true,
                //                 label:{
                //                     precision:0,
                //                     backgroundColor :'rgba(0,0,0,.8)',
                //                 }
                //             },
                //         },
                //         legend: {
                //             data: ['办卡年龄'],
                //             textStyle: {
                //                 color: 'white',
                //                 fontSize :14
                //             },
                //             top: '-5px'
                //         },
                //         grid: {
                //             top:'20%',
                //             left: '5%',
                //             right: '5%',
                //             containLabel: true
                //         },
                //         xAxis: [{
                //             type: 'category',
                //             data: res.data['user_age'].map(item=>item.name),
                //             axisLine: {
                //                 lineStyle: {
                //                     color: 'white'
                //                 }
                //             },
                //             axisLabel:{
                //                 fontSize :14
                //             },
                //         }],
                //         yAxis: [{
                //             type: 'value',
                //             splitNumber: 1,
                //             axisLine: {
                //                 show: false
                //             },
                //             axisLabel:{
                //                 fontSize :14
                //             },
                //             splitLine:{
                //                 show: false
                //             },
                //         }],
                //         series: [{
                //             name: '办卡年龄',
                //             type: 'bar',
                //             stack: '办卡年龄',
                //             barWidth: '25px',
                //             data: res.data['user_age'].map(item=>item.value),
                //             color: '#00aaff',
                //             label:{
                //                 show:true,
                //                 position:'top'
                //             }
                //         }],
                //         textStyle: {
                //             color: 'white'
                //         },
                //     },
                //     media: [ // 媒体查询
                //         {
                //             query: { // 这里写规则
                //                 maxWidth: 350
                //             },
                //             option: { // 这里写此规则满足下的 option
                //                 dataZoom: [//给x轴设置滚动条
                //                     {
                //                         type: 'inside',
                //                         realtime: true,
                //                         startValue: 0,
                //                         endValue: 3
                //                     }
                //                 ],
                //             }
                //         },
                //     ],
                // };
                // this.option3 = {
                //     baseOption:{
                //         tooltip: {
                //             trigger: 'item',
                //             formatter: '{a} <br/>{b} : {c} ({d}%)'
                //         },
                //         legend: {
                //             bottom: '10%',
                //             show:false,
                //             textStyle: {
                //                 color: 'white',
                //                 fontSize :14
                //             },
                //             data: res.data['user_race']['count'].map(item=>item.name)
                //         },
                //         series: [{
                //             name: '人数',
                //             type: 'pie',
                //             radius: [30,'45%'],
                //             roseType: 'area',
                //             left: '0%',
                //             right: '0%',
                //             top: '-10%',
                //             itemStyle: {
                //                 normal: {
                //                     label: {
                //                         show: true,
                //                         formatter: "{b} : {c} ({d}%)",
                //                         fontSize :14,
                //                     }
                //                 }
                //             },
                //             data: res.data['user_race']['count']
                //         }],
                //     },
                //     media: [ // 媒体查询
                //         {
                //             query: { // 这里写规则
                //                 maxWidth: 350
                //             },
                //             option: { // 这里写此规则满足下的 option
                //                 series: [{
                //                     name: '人数',
                //                     type: 'pie',
                //                     radius: [10,'15%'],
                //                 }]
                //             }
                //         },
                //     ],
                // };
                this.option3 = {
                    baseOption: {
                        color: ["#EAEA26", "#906BF9", "#FE5656", "#01E17E", "#3DD1F9", "#FFAD05"],
                        grid: {
                            left: -100,
                            top: 50,
                            bottom:0,
                            right: 10,
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: "{b} : {c} ({d}%)"
                        },
                        polar: {},
                        angleAxis: {
                            interval: 1,
                            type: 'category',
                            data: [],
                            z: 10,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#0B4A6B",
                                    width: 1,
                                    type: "solid"
                                },
                            },
                            axisLabel: {
                                interval: 0,
                                show: true,
                                color: "#0B4A6B",
                                margin: 8,
                                fontSize: 16
                            },
                        },
                        radiusAxis: {
                            min: 10,
                            max: 40,
                            interval: 20,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#0B3E5E",
                                    width: 1,
                                    type: "solid"
                                },
                            },
                            axisLabel: {
                                formatter: '{value} %',
                                show: false,
                                padding: [0, 0, 20, 0],
                                color: "#0B3E5E",
                                fontSize: 16
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#0B3E5E",
                                    width: 2,
                                    type: "solid"
                                }
                            }
                        },
                        calculable: true,
                        series: [{
                            type: 'pie',
                            radius: ["5%", "10%"],
                            hoverAnimation: false,
                            labelLine: {
                                normal: {
                                    show: false,
                                    length: 30,
                                    length2: 55
                                },
                                emphasis: {
                                    show: false
                                }
                            },
                            data: [{
                                name: '',
                                value: 0,
                                itemStyle: {
                                    normal: {
                                        color: "#0B4A6B"
                                    }
                                }
                            }]
                        }, {
                            type: 'pie',
                            radius: ["20%", "25%"],
                            hoverAnimation: false,
                            labelLine: {
                                normal: {
                                    show: false,
                                    length: 30,
                                    length2: 55
                                },
                                emphasis: {
                                    show: false
                                }
                            },
                            name: "",
                            data: [{
                                name: '',
                                value: 0,
                                itemStyle: {
                                    normal: {
                                        color: "#0B4A6B"
                                    }
                                }
                            }]
                        }, {
                            stack: 'a',
                            type: 'pie',
                            name:"人数",
                            radius: ['20%', '45%'],
                            roseType: 'area',
                            zlevel: 10,
                            label: {
                                normal: {
                                    show: true,
                                    formatter: "{b}({d}%)",
                                    textStyle: {
                                        fontSize: 12,
                                    },
                                    position: 'outside'
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: true,
                                    length: 20,
                                    length2: 55
                                },
                                emphasis: {
                                    show: false
                                }
                            },
                            data: res.data['user_race']['count']
                        },]
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                series: [{
                                    name: '人数',
                                    type: 'pie',
                                    radius: [10,'15%'],
                                }]
                            }
                        },
                    ],
                }
                this.option4 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                axis:'y',
                                snap:true,
                                type: 'cross' ,
                                animation :true,
                                label:{
                                    precision:0,
                                    backgroundColor :'rgba(0,0,0,.8)',
                                }
                            },
                        },
                        legend: {
                            data: ['办卡量'],
                            textStyle: {
                                color: 'white',
                                fontSize :14
                            },
                            top: '-5px'
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:0,
                            containLabel: true,
                        },
                        xAxis: [{
                            type: 'category',
                            data: res.data['user_number']['count'].map(item=>item.name),
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel:{
                                fontSize :12
                            },
                        }],
                        yAxis: [{
                            type: 'value',
                            splitNumber: 3,
                            axisLine: {
                                show: false
                            },
                            axisLabel:{
                                fontSize :14
                            },
                            splitLine:{
                                show:false,
                                lineStyle:{
                                    color:'#cccccc'
                                }
                            }

                        }],
                        series: [
                            {
                                name: "办卡量",
                                type: "line",
                                smooth: true, //平滑曲线显示
                                showAllSymbol: true, //显示所有图形。
                                symbol: "circle", //标记的图形为实心圆
                                symbolSize: 10, //标记的大小
                                itemStyle: {
                                    //折线拐点标志的样式
                                    color: "#058cff"
                                },
                                label:{
                                    show:true
                                },
                                lineStyle: {
                                    color: "#058cff"
                                },
                                areaStyle:{
                                    color: this.linear_color
                                },
                                data: res.data['user_number']['count'].map(item=>item.value)
                            },
                        ],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 10
                                    }
                                ],
                            }
                        },
                    ],
                };
                this.option5 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                axis:'y',
                                snap:true,
                                type: 'cross' ,
                                animation :true,
                                label:{
                                    precision:0,
                                    backgroundColor :'rgba(0,0,0,.8)',
                                }
                            },
                        },
                        legend: {
                            data: ['办卡人数','人口数量','办卡比例'],
                            textStyle: {
                                color: 'white',
                                fontSize :14
                            },
                            top: '-5px'
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:0,
                            containLabel: true
                        },
                        xAxis: [{
                            type: 'category',
                            data: res.data['user_city']['name'],
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel:{
                                fontSize :14,
                            },
                        }],
                        yAxis: [{
                            type: 'value',
                            splitNumber: 2,
                            axisLine: {
                                show: false
                            },
                            axisLabel:{
                                fontSize :14
                            },
                            splitLine:{show: false},
                        }],
                        series: [{
                            name: '办卡人数',
                            type: 'bar',
                            stack: '办卡人数',
                            barWidth: '20%',
                            data: res.data['user_city']['count'],
                            color: this.echarts_color1,
                            label:{
                                formatter:(param)=>{
                                    return (param.value/10) + '人';
                                },
                                rotate: 0,
                                show:true,
                                position:'top',
                                distance:0,
                                offset:[-10,0]
                            }
                        },
                            {
                                name: '人口数量',
                                type: 'bar',
                                stack: '人口数量',
                                barWidth: '20%',
                                data: res.data['user_city']['person'],
                                color: this.echarts_color2,
                                label:{
                                    formatter:(param)=>{
                                        return (param.value/5000).toFixed(2) + '万';
                                    },
                                    rotate: 0,
                                    distance:20,
                                    show:true,
                                    position:'top'
                                }
                            },
                            {
                                name: '办卡比例',
                                type: 'bar',
                                stack: '办卡比例',
                                barWidth: '20%',
                                data: res.data['user_city']['rate'],
                                color: this.echarts_color3,
                                label:{
                                    formatter:(param)=>{
                                        return (param.value/10000).toFixed(2) + '%';
                                    },
                                    rotate: 0,
                                    distance:0,
                                    show:true,
                                    position:'top'
                                }
                            }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 1
                                    }
                                ],
                                legend: {
                                    textStyle: {
                                        color: 'white',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    ],
                };
            },
            formatter(number) {
                const numbers = number.toString().split('').reverse()
                const segs = []
                while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                return segs.join(',').split('').reverse().join('')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/static/css/common";
</style>
